@charset "UTF-8";
@import "variables";
@import "mixin";

/* #gFooter
=========================================== */

#gFooter { 
	position: relative;
	small { 
		display: block;
		font-size: 1.1rem;
		padding: 5px 0;
		color: #fff;
		text-align: center;
		background: #0c2f44;
		letter-spacing: .05rem;
		@include pc { 
			font-size: 1.3rem;
			padding: 22px 0;
		}
	}
}
