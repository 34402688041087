@charset "UTF-8";
@import "variables";
@import "mixin";

/*! #gHeader
=========================================== */

@include sp { 
#gHeader { 
	position: relative;
	background: #fff;
	z-index: 100;
	height: 50px;
	.logo { 
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		padding: 12px 5px 0 0;
		text-align: center;
		a { 
			img { 
				height: 25px!important;
			}
		}
	}
	.btnGnav { 
		position: relative;
		float: left;
		width: 45px;
		height: 50px;
		-webkit-transition: .1s linear;
		transition: .1s linear;
		border-right: 1px solid #e5e5e5;
		span { 
			position: absolute;
			left: 10px;
			width: 24px;
			height: 3px;
			background: $colorNavy;
			-webkit-transition: .1s linear;
			transition: .1s linear;
		}
		span:nth-child(1) { 
			top: 10px;
		}
		span:nth-child(2) { 
			top: 18px;
		}
		span:nth-child(3) { 
			top: 26px;
		}
		span:nth-child(4) { 
			top: 33px;
			font-size: 0.7rem;
			font-weight: bold;
			text-align: center;
			background: none;
			color: $colorNavy;
			font-family: 'mplus-2c-bold';
			letter-spacing: -.05rem;
		}
		&.open { 
			background: $colorNavy;
	    span { 
		    background: #fff;
		    &:nth-child(1) { 
					top: 20px;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
				}
				&:nth-child(2) { 
					top: 20px;
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
				&:nth-child(3) { 
					opacity: 0;
				}
				&:nth-child(4) { 
					opacity: 0;
				}
		  }
		}
	}
	#btn-confirm { 
		float: right;
		img { 
			width: 75px;
		}
	}
}
}

@include pc { 
	
#gHeader { 
	position: relative;
	background: #fff;
	z-index: 100;
	height: 60px;
	margin-bottom: 22px;
	.inner { 
		width: $width;
		margin: 0 auto;
	}
	.logo { 
		position: relative;
		left: auto;
		right: auto;
		margin: 0;
		padding: 12px 5px 0 0;
		float: left;
		text-align: left;
		a { 
			img { 
				height: 34px !important;
			}
		}
	}
	#btn-confirm { 
		float: right;
		img { 
			width: 190px;
		}
	}
}	
	
	
}


/* .gNav
=========================================== */


.gNav,.sidenav { 
	display: none;
	position: absolute;
	top: 50px;
	left: 0;
	width: 100%;
	z-index: 100;
	background: #f4f3ed;
	.tit { 
		font-size: 1.6rem;
		font-weight: bold;
		color: #fff;
		background: $colorNavy;
		padding: 8px 0 9px 32px;
		position: relative;
		letter-spacing: .1rem;
		@include pc { 
			font-size: 1.8rem;
			padding: 9px 0 9px 42px;
		}
		&::after { 
			content: "";
			position: absolute;
			left: 10px;
			top: 11px;
			width: 15px;
			height: 18px;
			@include pc { 
				left: 14px;
				top: 14px;
				width: 19px;
				height: 23px;
			}
		}
		&Thema { 
			&::after { 
				background: url(../img/common/header/ico01.png) no-repeat center top;
				background-size: contain;
			}
		}
		&Place { 
			&::after { 
				height: 20px;
				background: url(../img/common/header/ico06.png) no-repeat center top;
				background-size: contain;
			}
		}
	}
	.list { 
		dt { 
			background-image: url(../img/common/ico_open.png);
			background-repeat: no-repeat;
			background-position: right 15px top 10px;
			background-size: 20px;
			background-color: #fff;
			font-size: 1.5rem;
			padding: 9px 0 9px 33px;
			position: relative;
			border-bottom: 1px solid #edeff1;
			font-weight: 600;
			@include pc { 
				font-size: 1.8rem;
				background-position: right 22px top 13px;
				background-size: 22px;
				padding: 8px 0 7px 38px;
				cursor: pointer;
			}
			&.on { 
				background-image: url(../img/common/ico_close.png);
				background-repeat: no-repeat;
				background-position: right 15px top 10px;
				background-size: 20px;
				background-color: #fff;
				@include pc { 
					background-position: right 22px top 13px;
					background-size: 22px;
				}
			}
			&::after { 
				content: "";
				position: absolute;
				left: 10px;
				top: 12px;
				width: 15px;
				height: 15px;
				@include pc { 
					left: 14px;
					top: 16px;
					width: 17px;
					height: 17px;
				}
			}
		}
		dd { 
			display: none;
			ul { 
				li { 
					float: right;
					width: 50%;
					a { 
						border-bottom: 1px solid #edeff1;
						border-left: 1px solid #edeff1;
						background: #fff;
						position: relative;
						padding: 10px 0 10px 18px;
						letter-spacing: -.05rem;
						font-size: 1.5rem;
						&::after { 
							content: "";
							position: absolute;
							left: 10px;
							top: 16px;
							width: 0;
							height: 0;
							border: 4px solid transparent;
					    border-left: 4px solid #f36259;
						}
					}
					&:nth-child(2n+1) { 
						float: left;
						a { 
							border-left: none;
						}
					}
				}
			}
			@include pc { 
				ul { 
					padding: 3px 0 17px;
					background: #fff;
					border-bottom: 1px solid #edeff1;
					li { 
						float:  none;
						width: 100%;
						a { 
							border-bottom: none;
							border-left: none;
							padding: 6px 0 2px 38px;
							&::after { 
								left: 25px;
								top: 16px;
								width: 0;
								height: 0;
								border: 4px solid transparent;
						    border-left: 4px solid #f36259;
							}
							&:hover { 
								color: #f36259;
							}
						}
						&:nth-child(2n+1) { 
							float: none;
						}
					}
				}
			}
		}
		&01 { 
			dt { 
				&::after { 
					background: url(../img/common/header/ico02.png) no-repeat center top;
					background-size: contain;
				}
			}
			dd { 
				ul { 
					li { 
						a { 
							&::after { 
								border-left: 4px solid #2eabc2;
							}
							&:hover { 
								color: #2eabc2;
							}
						}
					}
				}
			}
		}
		&02 { 
			dt { 
				&::after { 
					background: url(../img/common/header/ico03.png) no-repeat center top;
					background-size: contain;
					height: 13px;
				}
			}
		}
		&03 { 
			dt { 
				&::after { 
					background: url(../img/common/header/ico04.png) no-repeat center top;
					background-size: contain;
					height: 16px;
				}
			}
		}
		&04 { 
			@include pc { 
				margin-bottom: 20px;
			}
			dt { 
				&::after { 
					background: url(../img/common/header/ico05.png) no-repeat center top;
					background-size: contain;
				}
			}
		}
		&05,&06,&07,&08 { 
			dt { 
				padding: 9px 0 9px 21px;
				@include pc { 
					padding: 6px 0 8px 39px;
				}
				&::after { 
					content: "";
					position: absolute;
					left: 10px;
					top: 14px;
					width: 0;
					height: 0;
					border: 5px solid transparent;
			    border-left: 5px solid $colorNavy;
			    @include pc { 
			    	left: 23px;
						top: 19px;
			    }
		    }
			}
		}
	}
}
.sidenav { 
	display: block!important;
	position: relative!important;
	top: auto!important;
	left: auto!important;
}
#wrap-overlay { 
	background: rgba(12,47,68,0.7);
	display: none;
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
}


