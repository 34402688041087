@charset "UTF-8";
@import "variables";
@import "mixin";

/* Link　
=========================================== */

a { 
	outline: 0;
	color: $acolor;
	text-decoration: none;
	display: block;
}
a:link { 
	color: $acolor;
}
a:visited { 
	color: $acolor;
}
a:hover { 
	color: $acolor;
	text-decoration: none;
}
a:active { 
	color: $acolor;
}
@include pc { 
	a { 
		-webkit-transition: all ease-out 0.3s;
		-moz-transition: all ease-out 0.3s;
		-ms-transition: all ease-out 0.3s;
		transition: all ease-out 0.3s;
		/*&:hover { 
			opacity: 0.7;
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
		}*/
	}
}


/* Common Parts
=========================================== */

.cf:after { 
	content: '';
	display: block;
	clear: both;
}
@font-face { 
	font-family: 'MyYuMinchoM';
	font-weight: normal;
	src: local('YuMincho-Medium'),
	local('Yu Mincho Medium'),
	local('YuMinchoc-Regular');
}
@font-face { 
	font-family: 'MyYuMinchoM';
	font-weight: bold;
	src: local('YuMincho-Bold'),
	local('Yu Mincho');
}
@font-face { 
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face { 
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face { 
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face { 
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face { 
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

/* Layout
=========================================== */

* { 
	box-sizing: border-box;
}
html { 
	box-sizing: border-box;
}
body { 
	min-width: 320px;
	margin: 0 auto;
	font-size: 1.3rem;
	line-height: 1.47;
	letter-spacing: .23rem;
	color: $txtcolor;
	font-family: $yuGothic;
	background: $bgcolor;
	@include pc { 
		min-width: $width;
		margin: 0 auto;
		line-height: 1.875;
		font-size: 1.6rem;
	}
}
table { 
	table-layout: fixed;
	width: 100%;
	th { 
		word-wrap : break-word;
		overflow-wrap : break-word;
	}
}
img { 
	max-width: 100%;
	height: auto;
	@include pc { 
		max-width: 100%;
	}
}
#wrapper { 
	overflow: visible;
	position: relative;
}
#contents { 
	width: 100%;
	margin: 0 auto;
	@include pc { 
		width: $width;
		margin: 0 auto;
		position: relative;
		z-index: 11;
	}
}
#main { 
	width: 100%;
	@at-root #home #main { 
		background: none;
		padding: 0;
	}
	@include pc { 
		position: relative;
		padding: 0;
		width: 860px;
		float: left;
	}
}
#side { 
	@include pc { 
		width: 300px;
		float: right;
		margin-top: 53px;
	}
}
#home #side { 
	@include pc { 
		margin-top: 0;
	}
}
footer { 
	
}

/* contents
=========================================== */

	
#mainImg { 
	position: relative;
	margin: 0;
  &Home { 
	  background: url(../img/top/bg01_sp.png) no-repeat center top;
		background-size: cover;
		width: 100%;
		height: 270px;
		margin: 0;
		@include pc { 
			height: 410px;
			margin: 0 0 40px;
			float: left;
		}
	  .img { 
		  text-align: center;
			/*padding: 64px 22px 0;*/
			padding: 64px 0 0;
			@include pc { 
				padding: 80px 0 0;
			}
			img { 
				width: 276px;
				@include pc { 
					width: 559px;
				}
			}
	  }
  }
	.main { 
		background: $colorNavy;
		font-size: 1.9rem;
		color: #fff;
		padding: 15px 15px 16px;
		letter-spacing: .1rem;
		@include pc { 
			font-size: 2.6rem;
			color: #fff;
			padding: 34px 40px 33px;
			letter-spacing: .15rem;
			line-height: 1.45;
		}
	}
	@include pc { 
		width: 860px;
	}
}

#breadcrumb { 
	padding: 12px 15px 14px;
	font-size: 1.2rem;
	letter-spacing: .03rem;
	li { 
		display: inline-block;
		margin-right: 14px;
		a { 
			position: relative;
			display: inline-block;
			&::after { 
				position: absolute;
				content: ">";
				right: -14px;
				top: 0px;
			}
		}
	}
	@include pc { 
		padding: 0 0px 25px;
		font-size: 1.5rem;
		letter-spacing: .03rem;
		li { 
			display: inline-block;
			margin-right: 14px;
			a { 
				position: relative;
				display: inline-block;
				&::after { 
					position: absolute;
					content: ">";
					right: -14px;
					top: 0px;
				}
			}
		}
	}
}
